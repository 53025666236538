<template>
  <body class="flex items-center justify-center shadow-md">
    <div class="">
      <table
        class="w-full flex flex-row flex-no-wrap sm:bg-white overflow-hidden my-0 table-fixed"
      >
        <thead class="body__table--header__text divide-y divide-gray-200">
          <tr
            class="body__table--header flex flex-col flex-no wrap sm:table-row sm:rounded-none mb-2 sm:mb-0"
            v-for="data in this.customers"
            :key="data.id"
          >
            <th
              class="p-3 text-left uppercase text-sm"
              v-for="header in this.tableHeaders"
              :key="header.name"
            >
              <div
                :class="[
                  header.name === 'Company'
                    ? 'inline-flex sm:mt-0 cursor-pointer'
                    : 'inline-flex sm:mt-0',
                ]"
              >
                <span
                  :class="[
                    header.name === this.activeSorting &&
                    header.name === 'Company'
                      ? `underline`
                      : '',
                    'mr-1',
                  ]"
                >
                  {{ header.name }}
                </span>
                <svg
                  v-if="header.name === 'Company'"
                  xmlns="http://www.w3.org/2000/svg"
                  :class="[
                    header.name === this.activeSorting ? `underline` : '',
                    'ml-2 h-5 w-5',
                  ]"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    :d="[
                      this.activeSorting === header.name && header.isSort
                        ? 'M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z'
                        : this.activeSorting === header.name && !header.isSort
                        ? 'M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z'
                        : 'M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z',
                    ]"
                  />
                </svg>
              </div>
            </th>

            <th class="p-3 text-left" width="110px"></th>
          </tr>
        </thead>
        <tbody class="flex-1 sm:flex-none divide-y divide-gray-200 body__table--body">
          <tr
            v-for="(customer, idx) in this.customers"
            :key="customer.id"
            :class="[
              idx % 2 === 0 ? 'bg-white' : `bg-gray-50`,
              'flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0 hover:bg-gray-100 cursor-pointer',
            ]"
          >
            <td
              class="p-3 hover:underline"
              @click="this.$router.push(`/customer-detail/${customer.id}`)"
            >
              {{ customer.customerAccount }}
            </td>
            <td class="p-3 truncate">
              {{ customer.name }}
            </td>

            <td class="p-3 truncate">
              {{ customer.mainContactLastName }}
              {{ customer.mainContactFirstName }}
            </td>

            <td class="p-3 truncate">
              {{ customer.mainAddressPostalCode }}
              {{ customer.mainAddressCity }}
            </td>
            <UpdateCustomer />
          </tr>
        </tbody>
      </table>
      <BackendPagination
        :to="this.to"
        :from="this.from"
        :total="this.total"
        @firstPage="firstPage"
        @previousPage="previousPage"
        @nextPage="nextPage"
        @lastPage="goLastPage"
      />
    </div>
  </body>

  <!-- <ConfirmationDialog
    v-if="this.showMessage"
    :messageToShow="this.messageToShow"
    :subMessageToShow="this.subMessageToShow"
  />
  <ask-confirmation-dialog
    ref="askConfirmationDialog"
  ></ask-confirmation-dialog> -->
</template>

<script>
import axios from "axios";

import BackendPagination from "../BackendPagination.vue";

// import ConfirmationDialog from "../components/ConfirmationDialog.vue";
// import AskConfirmationDialog from "../components/AskConfirmationDialog.vue";
// import HoverTooltip from "../components/HoverTooltip.vue";
import UpdateCustomer from "../../components/customers/UpdateCustomer.vue";

export default {
  name: "ContactTable",
  components: {
    // ConfirmationDialog,
    // AskConfirmationDialog,
    // HoverTooltip,
    BackendPagination,
    UpdateCustomer,
  },
  props: ["tablePaginatedData", "page"],
  data() {
    return {
      tableHeaders: [
        { name: "Account" /*, sort: "companyName"*/, isSort: false },
        { name: "Name" /*, sort: "lastName"*/, isSort: false },
        { name: "Contact" /*, sort: "vip"*/, isSort: false },
        { name: "Address" /*, sort: "onCallDuty"*/, isSort: false },
      ],
      showMessage: false,
      messageToShow: "",
      subMessageToShow: "",
      activeSorting: "",
      customers: [],
      currentPage: 1,
      to: "",
      from: "",
      total: "",
      lastPage: "",
    };
  },
  methods: {
    async getCustomers() {
      this.isLoading = true;
      const res = await axios
        .get(
          `${this.$cookie.getCookie("API")}/api/v1/customers?page=${this.page}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .catch((err) => {
          console.error(err);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
            this.$store.dispatch("token", null);
            this.$router.push("/login");
          }
        });
      this.customers = res.data.response.data;
      this.to = res.data.response.to;
      this.from = res.data.response.from;
      this.total = res.data.response.total;
      this.lastPage = res.data.response.last_page;
    },
    firstPage() {
      let firstPage = 1;
      this.currentPage = firstPage;

      this.$router.push(`/customers/${firstPage}`);
    },
    nextPage() {
      let nextPage = parseInt(this.page) + 1;
      this.currentPage = nextPage;
      this.$router.push(`/customers/${nextPage}`);
      this.$forceUpdate();
    },
    previousPage() {
      let previousPage = null;
      if (this.page > 1) {
        previousPage = parseInt(this.page) - 1;
      } else {
        previousPage = parseInt(this.page);
      }
      this.currentPage = previousPage;
      this.$router.push(`/customers/${previousPage}`);
      this.$forceUpdate();
    },
    goLastPage() {
      this.currentPage = this.lastPage;
      this.$router.push(`/customers/${this.lastPage}`);
      this.$forceUpdate();
    },
    setSorting(sort, header) {
      this.$emit("sort", sort);
      this.activeSorting = header;
    },
    sendMessage(msg, subMsg) {
      this.showMessage = true;
      this.messageToShow = msg;
      this.subMessageToShow = subMsg;
    },
  },
  mounted() {
    this.getCustomers();
  },
};
</script>

<style>
.contact_table {
  overflow-x: hidden;
  overflow-y: hidden;
}
html,
body {
  height: 100%;
}

@media (min-width: 640px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    display: none;
  }
}
@media only screen and (max-width: 640px) {
  td {
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  }

  td:not(:last-child) {
    max-height: 50px;
    overflow-y: auto;
  }

  th {
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  }
}
</style>
