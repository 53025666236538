<template>
  <div v-if="this.customers.length > 0" class="p-10">
    <CustomersTable :page="page" />
  </div>
  <div v-else class="text-center mt-12">
    <svg
      class="mx-auto h-16 w-16 text-gray-400"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      aria-hidden="true"
    >
      <path
        vector-effect="non-scaling-stroke"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
      />
    </svg>
    <h3 class="mt-2 text-xl font-medium text-gray-900">No Customer</h3>
    <p class="mt-1 text-base text-gray-500">
      You don't have any customer at the moment.
    </p>
  </div>
  <loading
    v-model:active="isLoading"
    :can-cancel="false"
    :on-cancel="onCancel"
    :is-full-page="fullPage"
  />
</template>

<script>
import axios from "axios";

import CustomersTable from "../../components/customers/CustomersTable.vue";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  props: ["page"],
  components: {
    CustomersTable,
    Loading,
  },
  data() {
    return { customers: [], isLoading: true, fullPage: false };
  },
  methods: {
    async getCustomers() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/customers?page=${this.page}`,
          {}
        );
      } catch (error) {
        console.error(err);
        this.isLoading = false;

        if (err.response.status == 401) {
          localStorage.removeItem("token");
          this.$store.dispatch("token", null);
          this.$router.push("/login");
        }
        if (err.response.status == 401) {
          localStorage.removeItem("token");
          this.$store.dispatch("token", null);
          this.$router.push("/login");
        }
      }
    },
  },
  mounted() {
    this.getCustomers();
  },
};
</script>

<style></style>
