<template>
  <td
    class="px-6 py-6 whitespace-nowrap text-right text-sm font-medium inline-flex"
  >
    <button :class="`p-1 shadow-sm border-2 border-gray-500 rounded-md body__table--button cursor-pointer tooltip text-gray-600 mr-1`">
      <HoverTooltip text="Edit" />

      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-4 w-4"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"
        />
        <path
          fill-rule="evenodd"
          d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
          clip-rule="evenodd"
        />
      </svg>
    </button>
    <a href="javascript:void(0)" :class="`p-1 shadow-sm border-2 border-gray-500 rounded-md body__table--button cursor-pointer tooltip text-gray-600 mr-1`">
      <HoverTooltip text="Delete" />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-4 w-4"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
          clip-rule="evenodd"
        /></svg
    ></a>
  </td>
  <!-- <ask-confirmation-dialog
    ref="askConfirmationDialog"
  ></ask-confirmation-dialog>
  <ConfirmationDialog
    v-if="this.showMessage"
    :messageToShow="this.messageToShow"
    :subMessageToShow="this.subMessageToShow"
  /> -->
</template>

<script>
import axios from "axios";

// import ConfirmationDialog from "../components/ConfirmationDialog.vue";
// import AskConfirmationDialog from "../components/AskConfirmationDialog.vue";
import HoverTooltip from "../../components/HoverTooltip.vue";

export default {
  props: ["id", "name"],
  components: {
    // ConfirmationDialog,
    //   AskConfirmationDialog,
    HoverTooltip,
  },
  data() {
    return {
      closePutForm: true,
      showMessage: false,
      messageToShow: "",
      confirmationMessage: "",
      subMessageToShow: "",
    };
  },
  methods: {
    async deleteCti(
      id,
      title,
      confirmationMessage,
      confirmButton,
      goBack,
      successMsg
    ) {
      const r = await this.$refs.askConfirmationDialog.show({
        title: title,
        message: confirmationMessage,
        okButton: confirmButton,
        cancelButton: goBack,
        action: "delete",
      });
      if (r) {
        axios
          .delete(
            `${this.$cookie.getCookie("API")}/api/v1/ctis/${id}?customerAccount=${this.account}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            if (res) {
              this.$emit("activeBanner");
              this.$emit("success", true);
              this.$emit("successMsg", successMsg);
            }
          })
          .catch((error) => {
            console.error(error);
          if (error.response.status == 401) {
            localStorage.removeItem("token");
            this.$store.dispatch("token", null);
            this.$router.push("/login");
          }
          });
      }
    },

    cancelPutForm() {
      this.closePutForm = !this.closePutForm;
      this.$forceUpdate();
    },
    sendMessage(msg, subMsg) {
      this.showMessage = true;
      this.messageToShow = msg;
      this.subMessageToShow = subMsg;
    },
  },
};
</script>

<style></style>
